<template>
    <div class="home" v-if="true">
        <!-- <van-nav-bar title="我的" /> details.basic && details.basic.wechat_nickname-->
        <div class="new_hom_top">
            <div class="kongbai1"></div>
            <div class="new_hom_top_touxiang" @click="goMyInfo">
                <div class="new_hom_top_touxiang_lt">
                    <img :src="!!details.basic.wechat_avatar == true ? details.basic.wechat_avatar : require('@/assets/img/avatar.png')" style="width:100%;height:100%;border-radius:50%;" alt="">
                    <div class="new_hom_top_touxiang_lt_tag">
                        <div class="new_hom_top_touxiang_lt_tag_text"><span class="iconfont icon-zhaopingangwei1"></span>{{details.incumbent&&details.incumbent.incumbent_factory?'在职':'求职中'}}</div>
                    </div>
                </div>
                <div class="new_hom_top_touxiang_rt">
                    <div class="new_hom_top_touxiang_rt_on">
                        <div class="new_hom_top_touxiang_rt_on_top">{{ details.basic.wechat_nickname || details.basic.name }}</div>
                        <div class="new_hom_top_touxiang_rt_on_bot" @click="editNumber">{{ details.basic.mobile || '绑定手机号码' }}</div>
                    </div>
                </div>
            </div>
            <div class="new_hom_top_jingl" @click="goMyNews">
                <div class="iconfont icon-B">
                    <!-- <div class="is_read" >99+</div> -->
                    <div class="is_read" v-if="notifysCount > 0">{{notifysCount > 99 ? '99+' : notifysCount}}</div>
                </div>
            </div>
            <div class="new_hom_top_myinfo" @click="goMyInfo">我的信息</div>
            <div class="new_hom_top_dibu">
                <div class="new_hom_top_dibu_on">
                    <div class="new_hom_top_dibu_on_cd" @click="goMyLove">
                        <div class="new_hom_top_dibu_on_cd_top">{{sticsnotiobj.favorites_count||0}}</div>
                        <div class="new_hom_top_dibu_on_cd_bot">我的收藏<span class="iconfont icon-youjiantou"></span></div>
                    </div>
                    <div class="new_hom_top_dibu_on_cd" @click="goApplyList">
                        <div class="new_hom_top_dibu_on_cd_top">{{sticsnotiobj.apply_count||0}}</div>
                        <div class="new_hom_top_dibu_on_cd_bot">已报名<span class="iconfont icon-youjiantou"></span></div>
                    </div>
                    <div class="new_hom_top_dibu_on_cd" @click="goInterviewjilu">
                        <div class="new_hom_top_dibu_on_cd_top">{{sticsnotiobj.pending_interview||0}}</div>
                        <div class="new_hom_top_dibu_on_cd_bot">待面试<span class="iconfont icon-youjiantou"></span></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 两张图片是不一样的 -->
        <div class="new_hom_guangao new_hom_guangao1" v-if="details.incumbent&&details.incumbent.incumbent_factory">
            <div class="new_hom_guangao_zaizhi">
                <div class="new_hom_guangao_zaizhi_top">已入职工厂</div>
                <div class="new_hom_guangao_zaizhi_bot">{{details.incumbent.incumbent_factory}}<span class="nub_bold">{{ details.incumbent.incumbent_day }}</span>天</div>
            </div>
        </div>
        <div class="new_hom_guangao" v-else @click="gojobIndex">
            <div class="new_hom_guangao_lizhi">热门岗位任你选，成就你的<span class="nub_bold">高薪</span>之路</div>
        </div>
        <div class="new_hom_qianbao">
            <div class="new_hom_qianbao_on">
                <div class="new_hom_qianbao_on_cd" @click="gomyqianbao">
                    <div class="new_hom_qianbao_on_cd_top">
                        <div class="new_hom_qianbao_on_cd_top_img"><img style="width:100%;height:100%;" src="../../assets/img/qianbao.png" alt=""></div>
                    </div>
                    <div class="new_hom_qianbao_on_cd_bot">钱包</div>
                </div>
                <div class="new_hom_qianbao_on_cd" @click="goLookWages">
                    <div class="new_hom_qianbao_on_cd_top">
                        <div class="new_hom_qianbao_on_cd_top_img"><img style="width:100%;height:100%;" src="../../assets/img/gongzi.png" alt=""></div>
                    </div>
                    <div class="new_hom_qianbao_on_cd_bot">工资单</div>
                </div>
                <div class="new_hom_qianbao_on_cd" @click="goBorrowingList">
                    <div class="new_hom_qianbao_on_cd_top">
                        <div class="new_hom_qianbao_on_cd_top_img"><img style="width:100%;height:100%;" src="../../assets/img/jianzhi.png" alt=""></div>
                    </div>
                    <div class="new_hom_qianbao_on_cd_bot">借支</div>
                </div>
            </div>
        </div>

        <div class="new_hom_list">
            <div v-if="details.incumbent&&details.incumbent.incumbent_factory" class="new_hom_list_cd" @click="gozaizhixinx">
                <div class="new_hom_list_cd_lt">
                    <div class="new_hom_list_cd_lt_img"><img style="width:100%;height:100%" src="../../assets/img/rentou.png" alt=""></div>
                    <div class="new_hom_list_cd_lt_text">在职信息</div>
                </div>
                <div class="iconfont icon-youjiantou"></div>
            </div>
            <div class="new_hom_list_cd" @click="goRecommendList">
                <div class="new_hom_list_cd_lt">
                    <div class="new_hom_list_cd_lt_img"><img style="width:100%;height:100%" src="../../assets/img/qianbaomy.png" alt=""></div>
                    <div class="new_hom_list_cd_lt_text">推荐记录</div>
                </div>
                <div class="iconfont icon-youjiantou"></div>
            </div>
            <div v-if="details.basic.is_fac && details.incumbent.incumbent_member_info && (details.incumbent.incumbent_member_info.wechat_qrcode || details.incumbent.incumbent_member_info.mobile)" class="new_hom_list_cd" @click="goAddWechatCode(1)">
                <div class="new_hom_list_cd_lt">
                    <div class="new_hom_list_cd_lt_img"><img style="width:100%;height:100%" src="../../assets/img/dianhua.png" alt=""></div>
                    <div class="new_hom_list_cd_lt_text">联系驻厂</div>
                </div>
                <div class="iconfont icon-youjiantou"></div>
            </div>
            <div v-else class="new_hom_list_cd" @click="goAddWechatCode(2)">
                <div class="new_hom_list_cd_lt">
                    <div class="new_hom_list_cd_lt_img"><img style="width:100%;height:100%" src="../../assets/img/dianhua.png" alt=""></div>
                    <div class="new_hom_list_cd_lt_text">联系招聘经纪人</div>
                </div>
                <div class="iconfont icon-youjiantou"></div>
            </div>
        </div>
        <!-- <div class="background"></div>
        <div class="info">
            <div class="top" @click="goMyInfo">
                <div class="avatar"><img :src="!!details.basic.wechat_avatar == true ? details.basic.wechat_avatar : require('@/assets/img/avatar.png')" /></div>
                <div class="content">
                    <div class="displayFlex justifyContentBetten">
                        <div class="name">{{ details.basic.wechat_nickname || details.basic.name }}</div>
                        <div class="my_info">
                            我的信息
                            <van-icon name="arrow" />
                        </div>
                    </div>
                    <div @click="editNumber" style="width:3rem">{{ details.basic.mobile || '绑定手机号码' }}</div>
                    <div v-if="details.incumbent.incumbent_factory">在职工厂：{{ details.incumbent.incumbent_factory }}</div>
                </div>
            </div>
            <div class="entry" v-if="details.incumbent.incumbent_factory">
                <div class="block">
                    <div class="weight">{{ details.incumbent.incumbent_time }}</div>
                    <div>入职日期</div>
                </div>
                <div class="block">
                    <div class="weight">{{ details.incumbent.incumbent_day }}天</div>
                    <div>在职天数</div>
                </div>
            </div>
        </div>
        <div class="item displayFlex justifyContentBetten" v-if="details.incumbent.incumbent_member_info && details.incumbent.incumbent_member_info.operator_name">
            <div class="info_left">
                <div style="text-align: left;">
                    <p class="team_name">联系驻厂</p>
                    <p>姓名：{{ details.incumbent.incumbent_member_info.operator_name || '驻厂名称' }}</p>
                </div>
            </div>
            <div class="info_right">
                <a :href="`tel:${details.incumbent.incumbent_member_info.mobile}`" style="color:#333" v-if="details.incumbent.incumbent_member_info.mobile">
                    <div style="margin-right:0.4rem">
                        <img src="@/assets/img/tel.png" alt="" />
                        <p>电话咨询</p>
                    </div>
                </a>
                <div v-if="details.incumbent.incumbent_member_info.wechat_qrcode" @click="goAddWechatCode">
                    <img src="@/assets/img/wx.png" alt="" />
                    <p>添加微信</p>
                </div>
            </div>
        </div>
        <div class="cell">
            <div class="displayFlex justifyContentBetten" @click="goMyNews">
                <div class="displayFlex">
                    <img src="@/assets/img/home7.png" />
                    <div class="is_read" v-if="notifysCount > 0">{{notifysCount > 99 ? '99+' : notifysCount}}</div>
                    <div>我的消息</div>
                </div>
                <van-icon name="arrow" />
            </div>
            <div class="displayFlex justifyContentBetten" @click="goMyLove">
                <div class="displayFlex">
                    <img src="@/assets/img/home6.png" />
                    <div>我的收藏</div>
                </div>
                <van-icon name="arrow" />
            </div>
            <div class="displayFlex justifyContentBetten" @click="goApplyList">
                <div class="displayFlex">
                    <img src="@/assets/img/home1.png" />
                    <div>报名记录</div>
                </div>
                <van-icon name="arrow" />
            </div>
            <div class="displayFlex justifyContentBetten" @click="goRecommendList">
                <div class="displayFlex">
                    <img src="@/assets/img/home2.png" />
                    <div>推荐记录</div>
                </div>
                <van-icon name="arrow" />
            </div>
            <div class="displayFlex justifyContentBetten" @click="goLookWages" v-if="details.incumbent.incumbent_factory">
                <div class="displayFlex">
                    <img src="@/assets/img/home3.png" />
                    <div>查看工资</div>
                </div>
                <van-icon name="arrow" />
            </div>
            <div class="displayFlex justifyContentBetten" @click="goBorrowingList" v-if="details.incumbent.incumbent_factory">
                <div class="displayFlex">
                    <img src="@/assets/img/home4.png" />
                    <div>借支</div>
                </div>
                <van-icon name="arrow" />
            </div>
            <div class="displayFlex justifyContentBetten" @click="goDimission" v-if="details.incumbent.incumbent_factory">
                <div class="displayFlex">
                    <img src="@/assets/img/home5.png" />
                    <div>申请离职</div>
                </div>
                <van-icon name="arrow" />
            </div>
        </div> -->
    </div>
    <div v-else></div>
</template>

<script>
import { Toast } from "vant";
export default {
    data() {
        return {
            tou_img: require('../../assets/img/wx.png'),
            details: {
                basic: {
                    wechat_avatar: '',
                    wechat_nickname: '',
                    name: '',
                    mobile: ''
                },
                incumbent: {
                    incumbent_factory: '',
                    incumbent_time: '',
                    incumbent_day: ''
                }
            },
            sticsnotiobj: {},
            notifysCount: 0
        };
    },
    activated() {
        if (this.getQuery('team_id')) {
            localStorage.setItem('team_id', this.getQuery('team_id') || '')
        }
        // if (localStorage.getItem('token')) {
        this.getInfo();
        this.getNotifysCount()
        this.getStatisticsnoticon()
        // }
        this.$store.state.active = 1;
        this.$store.commit("showTabbar");
        // this.$store.commit('hideTabbar');
    },
    created() {

        this.$store.state.active = 1;
        this.$store.commit("showTabbar");
        this.$store.commit("getWxSDK");
        // this.$store.commit('hideTabbar');
        // this.getInfo();
        // this.getNotifysCount()
        // this.getStatisticsnoticon()
    },
    methods: {
        //获取基本信息统计信息
        getStatisticsnoticon() {
            if (localStorage.getItem('team_id')) {
                var data = {
                    team_id: localStorage.getItem('team_id'),
                }
                this.Request('client/home/count', 'get', data, true, false).then(res => {
                    if (res.status == 0) {
                        this.sticsnotiobj = res.data
                    }
                })
            } else {
                setTimeout(() => {
                    this.getStatisticsnoticon() // 获取team_id 的接口有延迟
                }, 100);
            }

        },
        getNotifysCount() {
            if (localStorage.getItem('team_id')) {
                var data = {
                    team_id: localStorage.getItem('team_id'),
                    filter_read: 1
                }
                this.Request('client/notifys/jobseeker', 'get', data, true, false).then(res => {
                    if (res.status == 0) {
                        this.notifysCount = res.data.count
                    }
                })
            } else {
                this.getNotifysCount()
            }
        },
        goMyNews() {
            this.$router.push({ path: '/myNews' });
        },
        // 获取业务员信息
        // getMemberInfo() {
        //     var data = {};
        //     data.member_id = sessionStorage.getItem("member_id")
        //     this.Request('client/user/member', 'get', data, true, false).then(res => {
        //         if (res.status == 0) {
        //             sessionStorage.setItem("team_id", res.data.team_id);
        //             sessionStorage.setItem("member_id", res.data.member_id);
        //             this.$store.state.memberInfo = res.data;
        //         }
        //     })
        // },
        getInfo() {
            // this.getMemberInfo()
            if (localStorage.getItem('team_id')) {
                this.Request('client/mine/info', 'get', { team_id: localStorage.getItem('team_id') }).then(res => {
                    if (res.status == 0) {
                        this.details = res.data;
                        if (res.data && res.data.incumbent && res.data.incumbent.jobseeker_id) {
                            localStorage.setItem("jobseeker_id", res.data.incumbent.jobseeker_id);
                        }
                    }
                })
            } else {
                setTimeout(() => {
                    this.getInfo() // 获取team_id 的接口有延迟
                }, 100);
            }

        },
        // 跳转到在职信息
        gozaizhixinx() {
            this.$router.push({ path: '/entryInfo', query: { is_fac: this.details.basic.is_fac } });
        },
        // 跳转到修改手机号码页面
        editNumber(e) {
            e.stopPropagation();
            this.$router.push({ path: '/phoneNum' });
        },
        // 跳转到我的信息
        goMyInfo() {
            if (this.details.basic.mobile) {
                this.$router.push({ name: 'MyInfo', params: { details: JSON.stringify(this.details.basic) } });
            } else {
                this.$router.push({ path: '/phoneNum' });
            }
        },
        //面试记录
        goInterviewjilu() {
            this.$router.push({ path: '/interviewjilu' });
        },
        // 跳转到报名记录
        goApplyList() {
            this.$router.push({ path: '/applyList' });
        },
        getQuery(variable) {
            var query = unescape(window.location.href);
            var lengths = query.split("?");
            var vars = lengths[lengths.length - 1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
        gojobIndex() {
            this.$store.state.active = 0;
            this.$set(this.$store.state, 'active', 0)
            this.$router.replace({ path: '/recruitmentList' });
        },
        // 跳转到推荐记录
        goRecommendList() {
            this.$router.push({ path: '/recommendList' });
        },
        goMyLove() {
            this.$router.push({ path: '/myLove' });
        },
        // 跳转到借支列表
        goBorrowingList() {
            this.$router.push({ path: '/borrowingList', query: { factory_id: this.details.incumbent.incumbent_factory_id, is_fac: this.details.basic.is_fac } });
        },
        // 跳转到查看工资
        goLookWages() {
            this.$router.push({ path: '/lookWages' });
        },
        //跳转到我的钱包
        gomyqianbao() {
            Toast('功能开发中，敬请期待')
            // this.$router.push({ path: '/walletlist' });
        },
        // 跳转到申请离职
        goDimission() {
            this.$router.push({ path: '/dimission' });
        },
        // 联系驻厂
        goAddWechatCode(type) {
            if (type == 1) { // 驻厂
                if (!this.details.incumbent.incumbent_member_info.wechat_qrcode && !this.details.incumbent.incumbent_member_info.mobile) {
                    Toast('驻厂暂未上传联系方式')
                    return
                } else if (!this.$store.state.memberInfo.wechat_qrcode && !this.$store.state.memberInfo.mobile) {
                    Toast('驻厂暂未上传联系方式')
                    return
                }
                this.$router.push({ path: '/addWechatCode', query: { type: 1, img: this.details.incumbent.incumbent_member_info.wechat_qrcode || '', name: this.details.incumbent.incumbent_member_info.operator_name || '', phone: this.details.incumbent.incumbent_member_info.mobile || '' } });
            } else { // 业务员
                if (!this.$store.state.memberInfo.wechat_qrcode && !this.$store.state.memberInfo.mobile) {
                    Toast('招聘经纪人暂未上传联系方式')
                    return
                }
                this.$router.push({ path: '/addWechatCode', query: { img: '', name: this.$store.state.memberInfo.operator_name || '', phone: this.$store.state.memberInfo.mobile || '' } });
            }

        }
    }
};
</script>

<style scoped>
.new_hom_list_cd {
    width: calc(100% - 60px);
    height: 119px;
    border-bottom: 1px solid #f8f8f8;
    margin-left: 30px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.new_hom_list_cd .iconfont {
    font-size: 20px;
}
.new_hom_list_cd_lt {
    width: 70%;
    height: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.new_hom_list_cd_lt_img {
    width: 44px;
    height: 44px;
    margin-top: -4px;
}
.new_hom_list_cd_lt_text {
    width: 70%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    margin-left: 15px;
    /* font-size: 26px; */
    font-family: PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
}
.new_hom_list_cd_rt {
    width: 15px;
    height: 25px;
    background: url("../../assets/img/jiangtou_left.png");
    background-size: 100% 100%;
}
.new_hom_list {
    width: calc(100% - 60px);
    min-height: 120px;
    background: #ffffff;
    border-radius: 10px;
    margin-left: 30px;
    margin-top: 30px;
}
.new_hom_qianbao {
    width: calc(100% - 60px);
    height: 160px;
    margin-left: 30px;
    margin-top: 30px;
    background: #ffffff;
    opacity: 1;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}
.new_hom_qianbao_on {
    width: calc(100% - 160px);
    height: 160px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.new_hom_qianbao_on_cd {
    /* width: 75px; */
    height: 95px;
}
.new_hom_qianbao_on_cd_top {
    /* width: 75px; */
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.new_hom_qianbao_on_cd_top_img {
    width: 48px;
    height: 48px;
}
.new_hom_qianbao_on_cd_bot {
    width: 100%;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin-top: 12px;
    /* font-size: 26px; */
    font-family: PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
}
.new_hom_guangao {
    width: calc(100% - 60px);
    height: 130px;
    margin-left: 30px;
    margin-top: 30px;
    background: url("../../assets/img/guanggao.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.new_hom_guangao1 {
    background: url("../../assets/img/guangao.png");
    background-size: 100% 100%;
}
.new_hom_guangao_zaizhi {
    width: calc(100% - 270px);
    height: 70px;
}
.new_hom_guangao_zaizhi_top {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    letter-spacing: 3px;
    font-weight: bold;
    color: #1a1a1a;
}
.new_hom_guangao_zaizhi_bot {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 20px;
    letter-spacing: 2px;
    font-family: PingFang SC;
    letter-spacing: 3px;
    font-weight: 400;
    color: #999999;
}
.nub_bold {
    color: rgba(10, 187, 185, 1) !important;
    font-size: 30px !important;
}
.new_hom_guangao_lizhi {
    /* width: calc(100% - 270px); */
    height: 50px;
    line-height: 50px;
    text-align: left;
    font-size: 23px;
    letter-spacing: 3px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #1a1a1a;
}
.kongbai1 {
    width: 100%;
    height: 60px;
}
.new_hom_top_jingl {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 6;
}
.new_hom_top_jingl_on {
    /* width: 40px;
    height: 40px; */
    position: relative;
    /* background: url('../../assets/img/lingdnag.png'); */
    /* background-size: 100%  100%; */
}
.new_hom_top_myinfo {
    position: absolute;
    right: 0;
    top: 115px;
    width: 140px;
    height: 50px;
    display: flex;
    align-items: center;
    /* line-height: 46px; */
    /* text-align: center; */
    justify-content: center;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 100px 0px 0px 100px;
    font-size: 26px;
    font-family: PingFang SC;
    color: #ffffff;
}
.new_hom_top_touxiang {
    width: calc(100% - 200px);
    height: 110px;
    margin-left: 30px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.new_hom_top_touxiang_lt {
    width: 110px;
    height: 110px;
    background-color: #42c4c4;
    padding: 4px;
    border-radius: 50%;
    position: relative;
}
.new_hom_top_touxiang_rt {
    margin-top: 3px;
    width: calc(100% - 135px);
    height: 110px;
    margin-left: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.new_hom_top_touxiang_rt_on {
    width: 100%;
    height: 85px;
}
.new_hom_top_touxiang_rt_on_top {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: left;
    font-size: 32px;
    font-family: PingFang SC;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    color: #ffffff;
}
.new_hom_top_touxiang_rt_on_bot {
    width: 100%;
    height: 35px;
    line-height: 35px;
    margin-top: 5px;
    text-align: left;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
}
.new_hom_top_touxiang_lt_tag {
    position: absolute;
    width: 110px;
    height: 30px;
    left: 5px;
    bottom: -10px;
    background: linear-gradient(180deg, #4cd6d6 0%, #23c1c1 100%);
    box-shadow: 0px 1px 20px rgba(21, 188, 186, 0.8);
    z-index: 5;
    border-radius: 21px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
/* .new_hom_top_touxiang_lt_tag_img {
    width: 17px;
    height: 15px;
    background: url("../../assets/img/zaizhiimg.png");
    background-size: 100% 100%;
} */
.new_hom_top_touxiang_lt_tag_text {
    /* width: 35px; */
    height: 22px;
    /* margin-left: 2px; */
    /* line-height: 22px; */
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
}
.new_hom_top {
    width: 100%;
    height: 340px;
    background: linear-gradient(136deg, #19c2c0 0%, #0aacab 100%);
    opacity: 1;
    position: relative;
}
.new_hom_top_dibu {
    position: absolute;
    bottom: 0;
    left: 30px;
    background: rgba(255, 255, 255, 0.06);
    width: calc(100% - 60px);
    height: 115px;
    border-radius: 10px 10px 0px 0px;
}
.new_hom_top_dibu_on {
    width: calc(100% - 80px);
    height: 115px;
    margin-left: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.new_hom_top_dibu_on_cd {
    min-width: 80px;
    height: 75px;
}
.new_hom_top_dibu_on_cd_top {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
}
.new_hom_top_dibu_on_cd_bot {
    min-width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
}
.new_hom_top_dibu_on_cd_bot > .iconfont {
    margin-left: 2px;
}
/*  */
.home {
    margin-bottom: 130px;
}
.background {
    background: #4c94f6;
    height: 210px;
    width: 100%;
    /* position: absolute; */
}
.info {
    background: #fff;
    margin: 0 30px;
    position: relative;
    margin-top: -1.93333rem;
    padding: 30px;
    width: calc(100vw - 120px);
    border-radius: 10px;
}
.info .top {
    display: flex;
    align-items: center;
}
.info .avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #eee;
    overflow: hidden;
}
.info .avatar img {
    width: 100%;
    height: 100%;
}
.info .content {
    text-align: left;
    margin-left: 20px;
    width: calc(100% - 140px);
}
.info .content > div {
    margin: 10px 0;
}
.info .content .name {
    font-weight: 700;
    font-size: 32px;
}
.info .content .my_info {
    color: #4c94f6;
}
.info .content .my_info i {
    top: 4px;
}
.info .entry {
    text-align: center;
    border-top: 1px solid #f8f8f8;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    padding-top: 30px;
}
.info .entry .block {
    width: 50%;
}
.info .entry .block .weight {
    font-weight: 700;
}

.cell {
    margin: 20px 30px 30px;
    background: #fff;
    border-radius: 10px;
    padding: 30px;
}

.cell .displayFlex {
    font-size: 28px;
    line-height: 50px;
    border-bottom: 1px solid #f8f8f8;
    padding: 20px 0;
}
.cell .displayFlex:first-of-type {
    padding-top: 0;
}
.cell .displayFlex:last-of-type {
    border: 0;
    padding-bottom: 0;
}
.cell .displayFlex img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}
.cell .displayFlex i {
    line-height: 50px;
}
.cell > .displayFlex {
    position: relative;
}

.item {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    margin: 20px 30px 0;
}
.info_left {
    text-align: left;
}
.info_left,
.info_right {
    display: flex;
    text-align: center;
    justify-content: space-between;
    font-size: 26px;
}
.info_left img {
    border-radius: 50%;
    width: 110px;
    height: 110px;
    margin-right: 20px;
}
.info_left .team_name {
    font-size: 32px;
    margin-bottom: 6px;
    margin-top: 10px;
    font-weight: 700;
}
.info_right img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
}
.is_read {
    background: red;
    position: absolute;
    right: -22px;
    top: -20px;
    margin-top: 8px;
    /* line-height: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 14px;
    padding: 0 10px;
    min-width: 10px;
    height: 30px;
    text-align: center;
    color: #fff;
}
.icon-B {
    color: #fff;
    font-size: 40px;
}
.icon-zhaopingangwei1 {
    font-size: 24px;
    margin-right: 3px;
}
.new_hom_list .icon-youjiantou {
    color: #999;
}
</style>

